import React from 'react';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import CookiePolicy from 'views/CookiePolicy';

const CookiePolicyPage = () => {
  return (
    <WithLayout
      component={CookiePolicy}
      layout={Main}
    />
  )
};

export default CookiePolicyPage;
